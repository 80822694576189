import _ from 'lodash';
import * as React from 'react';
import moment from 'oo-moment';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import { DataProxy } from 'apollo-cache';
import gql from 'graphql-tag';
import {
  Order,
  Query as QueryOrderResults,
  Mutation as MutationOrderResults,
  QueryorderByIdArgs,
  SHIPPING_SPEED_ENUM,
  OrderQuoteItem,
} from 'inkp-order-sdk/types.g';
import { SHIPPING_SPEED_ENUM_MAPPING } from 'inkp-order-sdk/order';
import {
  Query as QueryUserResults,
  Mutation as MutationUserResults,
  User,
} from 'inkp-user-sdk/types.g';
import { isUserRegistered, validatePassword } from 'inkp-user-sdk/user';
import { ProductItem, QueryproductByIdArgs, Query as QueryProductResults } from 'inkp-product-sdk/types.g';
import { routes, path } from 'inkp-routes/public';
import Helmet from 'react-helmet';
import Loading from 'inkp-components/dist/Components/Loading';
import Button from 'inkp-components/dist/Components/Button';
import Input from 'inkp-components/dist/Components/Input';
import Alert from 'inkp-components/dist/Components/Alert';

// helper functions
import { GetDeliverByDate, PRODUCT_QUERY } from '../../../util/Product';
import { GET_MY_ORDER_BY_ID_QUERY, MutationUpdateOrderUserId, MUTATION_UPDATE_ORDER_USER_ID } from '../../../util/orders';
import RelatedProducts from '../../../components/RelatedProducts';
import { MUTATION_REGISTER_USER, MutationRegisterUser, QueryUserByEmail, QUERY_USER_BY_EMAIL, MUTATION_SIGN_IN, MutationSignIn } from '../../../graphql/users';
import { parseProductItemId } from 'inkp-product-sdk';
import { updateCurrentUser, QUERY_CURRENT_USER, QueryCurrentUser } from '../../../graphql/users';
import { formatPrice, QUERY_CART_BY_USER } from '../../../util/Checkout';
import { findServiceError } from '../../../util/apollo';

// GTM helpers
import GTM from '../../../util/gtm';
import { GTMTypes, GTMEvents } from '../../../interfaces/GTM';

// constants
const INKPOP_USER_BLURB: string = 'Inkpop customer';

class OrderQuery extends Query<QueryOrderResults, QueryorderByIdArgs> {}
class ProductQuery extends Query<QueryProductResults, QueryproductByIdArgs> {}

interface RouteParams {
  orderId: string;
}

const renderWarningMessage = (message: string) => (
  <div className="mt-p5 ml-1 fs-xs fw-bold color-gray-400">
    <i className="mdi mdi-alert fs-md fs-sm-icon color-gray-400 pr-p25" />
    {message}
  </div>
);

const renderErrorMessage = (errors: any) => {
  if (_.isEmpty(errors)) return null;

  return errors.map(({ message }: { message: string }, index: number) => (
    <div key={index}>
      {message && (
        <div className="mt-p5 ml-1 fs-xs fw-bold color-red">
          <i className="mdi mdi-alert fs-sm-icon color-red pr-p25" />
          {message}
        </div>
      )}
    </div>
  ));
};

const getDeliveryDate = (order: Order): string => {
  let deliveryDate: string = '';

  if (order.dueAt) {
    return `${order.dueAt}`;
  }
  // remove this when dueAt is populated in order.
  const { quote } = order;
  const orderedAt: string = `${order.orderedAt}`;
  const { items } = quote;
  const slowestSpeed: SHIPPING_SPEED_ENUM = items.reduce(
    (slowestSpeed: SHIPPING_SPEED_ENUM, currentOrderQuoteItem: OrderQuoteItem) => {
      const productItem: Partial<ProductItem> | undefined = currentOrderQuoteItem.product.productItem;
      if (productItem) {
        const currentCartItemSpeed: SHIPPING_SPEED_ENUM | undefined = productItem.speed;
        if (
          currentCartItemSpeed &&
          SHIPPING_SPEED_ENUM_MAPPING[currentCartItemSpeed] > SHIPPING_SPEED_ENUM_MAPPING[slowestSpeed]
        ) {
          return currentCartItemSpeed;
        }
      }
      return slowestSpeed;
    },
    SHIPPING_SPEED_ENUM.THREE_DAY
  );
  deliveryDate = GetDeliverByDate(new Date(orderedAt).toString(), slowestSpeed);

  return deliveryDate;
};

const renderOrderDetails = ({ order, user }: { order: Order; user: User }) => {
  const { id, quote } = order;
  const { email } = user;
  const {
    amounts: { total },
  } = quote;
  const expectedDeliveryDate: string = moment(getDeliveryDate(order)).format('dddd, MMMM DD');

  let orderDetailsLink: string;
  if (isUserRegistered(user)) {
    orderDetailsLink = path(routes.app.order.getById, { orderId: id });
  } else if (email) {
    orderDetailsLink = path(routes.app.order.getById, { orderId: id, email });
  } else {
    orderDetailsLink = path(routes.app.account.trackOrder);
  }

  return (
    <div className="w-full flex flex-wrap flex-col justify-between p-1 bgc-blue-50 bc-navy-100 bw-1 br">
      <div className="w-full flex mb-1">
        <p className="w-1/2 ta-left">
          <span className="pr-p5 fw-extra-bold capitalize">order</span>
          {id}
        </p>
        <p className="w-1/2 ta-right">
          <Link to={orderDetailsLink} className="color-primary fw-bold capitalize">
            view details
          </Link>
        </p>
      </div>
      <div className="w-full flex">
        <p className="w-2/3 ta-left">
          <span className="d-b color-navy-500 fw-bold capitalize">expected delivery</span>
          <span className="d-b">{expectedDeliveryDate}</span>
        </p>
        <p className="w-1/3 ta-right">
          <span className="d-b color-navy-500 fw-bold capitalize">total</span>
          <span className="d-b">${formatPrice(total)}</span>
        </p>
      </div>
    </div>
  );
};

const AccountSignUp = ({
  userEmail,
  userName,
  onSignUpSuccess,
}: {
  userEmail: string;
  userName: string;
  onSignUpSuccess: () => void;
}) => {
  const [password, setPassword] = React.useState({
    value: '',
    show: false,
  });
  const [errors, setErrors] = React.useState([] as { message?: string, field: string }[]);

  return (
    <MutationRegisterUser
      mutation={MUTATION_REGISTER_USER}
      update={(cache: DataProxy, { data }: { data: MutationUserResults }) => {
        updateCurrentUser(cache, data.register);
        GTM.push(GTMTypes.USER);
        GTM.fire(GTMEvents.ACCOUNT_SIGNUP);
        onSignUpSuccess();
      }}
    >
      {(
        registerUser,
        {
          data: mutationRegisterUserData,
          error: mutationRegisterUserError,
          loading: mutationRegisterUserLoading,
        }
      ) => {
        const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
          const passwordValidation = validatePassword(password.value);
          if (!passwordValidation.valid) {
            setErrors([{ message: 'Minimum 6 characters', field: 'password' }]);
            return;
          }
          setErrors([]);
          return registerUser({
            variables: {
              userData: {
                email: userEmail,
                name: userName,
                password: password.value,
              },
            },
          });
        };
        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          setPassword({
            ...password,
            value: e.target.value,
          });
        };
        const onRightIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
          setPassword({ ...password, show: !password.show });
        }

        return (
          <>
            <p className="mt-2 mb-p75 color-primary fw-bold md:mt-0">Save Your Information for Future Orders</p>
            <ul className="m-0 p-0 pl-1 color-primary">
              <li className="mb-p5">
                <span className="color-navy">Save time by not having to retype address and other information.</span>
              </li>
              <li className="mb-p5">
                <span className="color-navy">Access to order tracking and faster customer support</span>
              </li>
              <li className="mb-p5">
                <span className="color-navy">Access past purchases and invoices.</span>
              </li>
              <li>
                <span className="color-navy">Receive special rewards and promotions.</span>
              </li>
            </ul>
            <Input
              label="Password"
              value={password.value}
              onChange={onChange}
              className="mt-1p5"
              rightIcon={password.show ? 'eye-off' : 'eye'}
              onRightIconClick={onRightIconClick}
              inputType={password.show ? 'text' : 'password'}
              error={errors.length > 0}
            />
            {renderWarningMessage('Minimum 6 characters')}
            <div className="mt-1p5 md:w-3/5">
              <Button
                size="xl"
                className="fs-mlg"
                block={true}
                loading={mutationRegisterUserLoading}
                disabled={mutationRegisterUserLoading}
                onClick={onButtonClick}
              >
                Create Account
              </Button>
            </div>
            <div className="fs-xs mt-1 ta-center md:mt-1p5 md:ta-left">
              By registering, you confirm that you've read and accepted our&nbsp;
              <Link
                to={routes.app.termsOfService}
                target="_blank"
                className="color-navy fw-normal"
                style={{ textDecoration: 'underline' }}
              >
                Terms of Use
              </Link>
              &nbsp;and&nbsp;
              <Link
                to={routes.app.privacyPolicy}
                target="_blank"
                className="color-navy fw-normal"
                style={{ textDecoration: 'underline' }}
              >
                Privacy Policy
              </Link>
              .
            </div>
          </>
        );
      }}
    </MutationRegisterUser>
  );
};

const AccountSignIn = ({
  userEmail,
  orderId,
  onSignInSuccess,
}: {
  userEmail: string;
  orderId: string;
  onSignInSuccess: () => void;
}) => {
  const [password, setPassword] = React.useState({
    value: '',
    show: false,
  });
  const [errors, setErrors] = React.useState([] as { message?: string, field: string }[]);
  
  const setSignInError = (error: any) => {
    const serviceError = findServiceError(error);
    if (serviceError && serviceError.extensions && serviceError.extensions.code === 'INVALID_CREDENTIALS') {
      return setErrors([{ field: serviceError.data.data.field, message: serviceError.data.message }]);
    }
    setErrors([{ field: 'password', message: 'Error encountered'}]);
  };

  return (
    <MutationUpdateOrderUserId
      mutation={MUTATION_UPDATE_ORDER_USER_ID}
      onCompleted={() => onSignInSuccess()}
    >
      {(updateOrderUserId) => {
        return (
          <MutationSignIn
            mutation={MUTATION_SIGN_IN}
            update={(cache: DataProxy, { data }: { data: MutationUserResults }) => {
              updateCurrentUser(cache, data.signIn);
            }}
            refetchQueries={[{ query: QUERY_CART_BY_USER }]}
            onCompleted={() => {
              updateOrderUserId({ variables: { orderId } });
            }}
            onError={setSignInError}
          >
            {(
              signIn,
              {
                data: mutationSignInData,
                error: mutationSignInError,
                loading: mutationSignInLoading,
              }
            ) => {
              const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
                const passwordValidation = validatePassword(password.value);
                if (!passwordValidation.valid) {
                  setErrors([{ message: 'Minimum 6 characters', field: 'password' }]);
                  return;
                }
                setErrors([]);
                return signIn({
                  variables: {
                    email: userEmail,
                    password: password.value,
                  },
                });
              };
              const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                setPassword({
                  ...password,
                  value: e.target.value,
                });
              };
              const onRightIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
                setPassword({ ...password, show: !password.show });
              }

              return (
                <>
                  <div className="mt-2 mb-p75 color-primary fw-bold md:mt-0">Looks like you have an account with us.</div>
                  <div>
                    Log in to keep track of your orders on your existing account
                    <span className="fw-bold"> {userEmail}</span>
                  </div>
                  <Input
                    label="Password"
                    value={password.value}
                    onChange={onChange}
                    className="mt-1p5"
                    rightIcon={password.show ? 'eye-off' : 'eye'}
                    onRightIconClick={onRightIconClick}
                    inputType={password.show ? 'text' : 'password'}
                    error={errors.length > 0}
                    helper={renderErrorMessage(_.filter(errors, { field: 'password' }))}
                  />
                  <div className="mt-1p5 md:w-3/5">
                    <Button
                      size="xl"
                      className="fs-mlg"
                      block={true}
                      loading={mutationSignInLoading}
                      disabled={mutationSignInLoading}
                      onClick={onButtonClick}
                    >
                      Log In
                    </Button>
                  </div>
                </>
              );
            }}
          </MutationSignIn>
        );
      }}
    </MutationUpdateOrderUserId>
  );
}

export default class OrderConfirmation extends React.Component<RouteComponentProps<RouteParams>> {
  private renderOrderNotFound(orderId?: string) {
    return (
      <div className="flex flex-col w-full h-full p-1">
        <Alert
          alerts={[
            {
              type: 'error',
              title: 'Failed to lookup order',
              content: `Could not find order ${orderId}`,
            },
          ]}
        />
      </div>
    );
  }

  private renderUserNotFound() {
    return (
      <div className="flex flex-col w-full h-full p-1">
        <Alert
          alerts={[
            {
              type: 'error',
              title: 'Failed to lookup current user',
              content: `Error while loading current user`,
            },
          ]}
        />
      </div>
    );
  }

  componentDidMount() {
    const {
      match: {
        params: { orderId },
      },
    } = this.props;
    setTimeout(() => {
      GTM.push(GTMTypes.USER);
    }, 0);
  }

  render() {
    const {
      match: {
        params: { orderId },
      },
      history,
    } = this.props;


    return (
      <React.Fragment>
        <Helmet>
          <title>Inkpop Order Confirmation</title>
          <meta
            name="description"
            content="Choose Inkpop for your custom shirt printing needs. Our Happiness Guarantee ensures your satisfaction with both print quality and on-time delivery"
          />
        </Helmet>
        <div className="flex flex-wrap w-full h-full">
          {!orderId ? (
            <div className="w-full md:w-12/16 md:m-auto md:my-0">{this.renderOrderNotFound()}</div>
          ) : (
            <QueryCurrentUser query={QUERY_CURRENT_USER}>
              {({ data: queryCurrentUserData, error: queryCurrentUserError, loading: queryCurrentUserLoading }) => {
                if (queryCurrentUserLoading || !queryCurrentUserData) {
                  return (
                    <div className="w-full md:w-12/16 md:m-auto md:my-0">
                      <Loading />;
                    </div>
                  );
                }

                if (queryCurrentUserError) {
                  console.error(queryCurrentUserError);
                  return <div className="w-full md:w-12/16 md:m-auto md:my-0">{this.renderUserNotFound()}</div>;
                }

                if (!queryCurrentUserData.currentUser) {
                  console.error("Couldn't load data for Current User.");
                  return <div className="w-full md:w-12/16 md:m-auto md:my-0">{this.renderUserNotFound()}</div>;
                }

                const user: User = queryCurrentUserData.currentUser;
                const userName: string = user.name || INKPOP_USER_BLURB;
                const userEmail: string = user.email as string;
                const isRegistered: boolean = isUserRegistered(user);

                return (
                  <OrderQuery query={GET_MY_ORDER_BY_ID_QUERY} variables={{ id: orderId }}>
                    {({ data: queryOrderByIdData, error: queryOrderByIdError, loading: queryOrderByIdLoading }) => {
                      if (queryOrderByIdLoading) {
                        return (
                          <div className="w-full md:w-12/16 md:m-auto md:my-0">
                            <Loading />;
                          </div>
                        );
                      }

                      if (queryOrderByIdError || !queryOrderByIdData) {
                        console.error(queryOrderByIdError);
                        return (
                          <div className="w-full md:w-12/16 md:m-auto md:my-0">{this.renderOrderNotFound(orderId)}</div>
                        );
                      }

                      if (!queryOrderByIdData.orderByIdAndUser) {
                        console.error(`Couldn't load data for Order Id ${orderId}`);
                        return (
                          <div className="w-full md:w-12/16 md:m-auto md:my-0">{this.renderOrderNotFound(orderId)}</div>
                        );
                      }

                      const { orderByIdAndUser: order } = queryOrderByIdData;
                      const { productId } = parseProductItemId(order.quote.items[0].product.productItemId);

                      return (
                        <React.Fragment>
                          <div className="flex flex-wrap w-full pt-2 pb-1p5 bgc-blue-50 md:py-3">
                            <div className="d-n md:d-b md:w-2/16" />
                            <div className="d-b px-1 md:w-6/16">
                              <h2 className="mb-1p5 capitalize fw-extra-bold">thank you for your order</h2>
                              <p className="mb-2 md:mb-1p5">
                                Hi {userName}, thank you for your order! We have received your order and sent the copy
                                of the order confirmation to your email {userEmail}.
                              </p>
                              {!isRegistered &&
                                renderOrderDetails({
                                  order,
                                  user,
                                })}
                            </div>
                            <div className="d-n md:d-b md:w-1/16" />
                            <div className="d-b px-1 md:w-5/16">
                              {isRegistered ? (
                                renderOrderDetails({ order, user })
                              ) : (
                                <QueryUserByEmail query={QUERY_USER_BY_EMAIL} variables={{ email: userEmail }}>
                                  {({ error, loading, data }) => {
                                    if (error) return null;
                                    const accountExists = !!(data && data.userByEmail);

                                    if (accountExists) return (
                                      <AccountSignIn
                                        userEmail={userEmail}
                                        orderId={orderId}
                                        onSignInSuccess={() => {
                                          history.push(`${routes.app.account.myAccount}?tab=Orders`);
                                        }}
                                      />
                                    );

                                    return (
                                      <AccountSignUp
                                        userEmail={userEmail}
                                        userName={userName}
                                        onSignUpSuccess={() => {
                                          history.push(`${routes.app.account.myAccount}?tab=Orders`, {
                                            registrationSuccessful: true,
                                          });
                                        }}
                                      />
                                    );
                                  }}
                                </QueryUserByEmail>
                              )}
                            </div>
                            <div className="d-n md:d-b md:w-2/16" />
                          </div>
                          <div className="flex flex-wrap w-full">
                            <div className="d-n md:d-b md:w-2/16" />
                            <div className="w-full mt-1p5 px-1 md:w-12/16">
                              <h3 className="mb-1 fw-extra-bold capitalize md:mb-1p5">recommended for you</h3>
                              <ProductQuery query={PRODUCT_QUERY} variables={{ id: productId }}>
                                {({
                                  data: queryProductData,
                                  error: queryProductError,
                                  loading: queryProductLoading,
                                }) => {
                                  if (queryProductLoading || !queryProductData) {
                                    return <Loading />;
                                  }

                                  if (queryProductError) {
                                    console.error(queryProductError);
                                    return;
                                  }

                                  if (!queryProductData.productById) {
                                    console.error(`Couldn't load related products for productId: ${productId}`);
                                    return;
                                  }

                                  const {
                                    productById: { relatedProducts },
                                  } = queryProductData;
                                  if (relatedProducts && relatedProducts.length > 0) {
                                    return (
                                      <RelatedProducts
                                        relatedProducts={relatedProducts}
                                        containerClass="w-1/2 md:w-1/4"
                                        cardClass="pb-1 md:pb-0"
                                      />
                                    );
                                  }
                                }}
                              </ProductQuery>
                              <div className="w-full pb-3 md:w-1/3 md:mx-auto md:mt-1p5">
                                <Button
                                  className="m-auto capitalize"
                                  block={true}
                                  color="inverseSecondary"
                                  onClick={() =>
                                    this.props.history.push(path(routes.app.product.category, { category: 't-shirts' }))
                                  }
                                >
                                  keep shopping
                                </Button>
                              </div>
                            </div>
                            <div className="d-n md:d-b md:w-2/16" />
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </OrderQuery>
                );
              }}
            </QueryCurrentUser>
          )}
        </div>
      </React.Fragment>
    );
  }
}
